import React, { createContext, useState, useEffect, useReducer } from 'react'
// KEYCLOACK
import Keycloak from 'keycloak-js'
import { toast } from 'react-toastify';
import { customFetch, customFetch3 } from '../helper/api-helper/apiHerlpers';
import apiUrls from '../constants/apiUrls';
import { format } from 'react-string-format';

import { isAdminUser, keycloakUser } from '../helper/context/actions';
import { useHistory } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import { SmarkReducer, initialState } from "../helper/context/reducer";

const KeycloackContext = createContext();
const SmarkContext = createContext();
const SmarkDispatchContext = createContext();

export function useKeycloakState() {
  const context = React.useContext(KeycloackContext);
  if (context === undefined) {
    throw new Error("useSmarkState must be used within a KeycloakProvider");
  }

  return context;
}
export function useSmarkState() {
  const context = React.useContext(SmarkContext);
  if (context === undefined) {
    throw new Error("useSmarkState must be used within a SmarkProvider");
  }

  return context;
}

export function useSmarkDispatch() {
  const context = React.useContext(SmarkDispatchContext);
  if (context === undefined) {
    throw new Error("useSmarkDispatch must be used within a SmarkProvider");
  }

  return context;
}
const KeycloackContextProvider = (props) => {
    const [ keycloackValue, setKeycloackValue ] = useState(null)
    const [ authenticated, setAuthenticated ] = useState(false)
    const [user, dispatch] = useReducer(SmarkReducer, initialState);
    
    const history = useHistory();
    const setKeycloack = () => {
        const keycloak = Keycloak({
            realm: "smark",
            url: "https://smartwcm-keycloak-smartwcm-qa1.dark.swcm.link/",
            clientId:"smark-web",
        })

        keycloak.init({
            onLoad: 'check-sso', 
            
            }).then(authenticated => {
            setKeycloackValue(keycloak);
            
            
            if(authenticated === true ){
              setAuthenticated(authenticated);
              trackPromise(
                keycloakUser(dispatch, keycloak.tokenParsed.preferred_username,keycloak.token).then((response) => {
                    if (response.userId) {
                        history.push('/dashboard');
                    } else {
                        let error = response[0].errorMap;
                        let message = Object.values(error);
                        toast.error(message[0], {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }).catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
            );
  
                              }
                              else{
        
                                localStorage.removeItem('currentUser');
                                localStorage.removeItem('keycloakToken');
                                
                                
                              }
  
                              
                        })
                        }
            
  
    const logout = () => {
        setKeycloack(null)
        setAuthenticated(false)
        if (keycloackValue) {
          keycloackValue.logout()
        }
        
       
        localStorage.removeItem('keycloakToken');
        localStorage.removeItem('currentUser');
    }
  const login = ()=>
  {
    if(keycloackValue === null)
    {
    toast.error("Please check the Keycloak Server", {
        position: toast.POSITION.TOP_RIGHT
    });
  }
  else {
    keycloackValue.login() 
    
       
  }
      
  }
  useEffect(() => {
    // Save the token in localStorage when it changes
    if (keycloackValue && keycloackValue.token) {
      localStorage.setItem('keycloakToken', keycloackValue.token);
    }
  }, [keycloackValue]);

  useEffect(() => {
    setKeycloack()
}, [])

    return (
        <KeycloackContext.Provider
            value={{
                keycloackValue,
                authenticated,
                logout,
                login
            }}
        >
     <SmarkContext.Provider value={user}>
      <SmarkDispatchContext.Provider value={dispatch}>
      {props['children']}
      </SmarkDispatchContext.Provider>
    </SmarkContext.Provider>
            
        </KeycloackContext.Provider>
    )
}

export default KeycloackContextProvider;
