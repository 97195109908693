const apiCommonParams = {
    applicationid: 252,
    siteid: 171,
    userid: 0,
    swcmTicket: '69f2ed48-6850-4e0f-a2e9-90b54c50fd28',
    userCreated: 0,
    userModified: 0,
    createdTime: new Date().getTime(),
    updatedTime: new Date().getTime(),
    startDate: new Date().getTime(),
    endDate: new Date().getTime(),
}

if (localStorage.getItem("currentUser")) {
    var user = JSON.parse(localStorage.getItem("currentUser"));
    if (user) {
        apiCommonParams.userid = user.userId;
        apiCommonParams.userCreated = user.userId;
        apiCommonParams.userModified = user.userId;
    }
}

export default apiCommonParams;