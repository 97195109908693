import { customFetch } from '../api-helper/apiHerlpers';
import apiUrls from '../../constants/apiUrls';
import { format } from 'react-string-format';

import moment from 'moment';


// const ROOT_URL = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyAP-x4GzxTaU51CFXifRyJDRa9PQf1woVU';


export async function loginUser(dispatch, loginPayload) {
    const requestOptions = {
        method: 'POST',
        redirect: 'follow',
        body: loginPayload
    };

    
    try {
        let response = await fetch(apiUrls.loginPath, requestOptions);
        let data = await response.json();
        if (data && data.userData && data.userData.userId) {
            dispatch({ type: 'REQUEST_LOGIN' });
            data['userData']['isAdminUser'] = await customFetch(format(apiUrls.isAdminPath, data.userData.userId));
            localStorage.setItem('currentUser', JSON.stringify(data['userData']));
            return data['userData'];
        } else {
            dispatch({ type: 'LOGIN_ERROR', error: data.errorDetails });
            return data.errorDetails;
        }
    } catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: error });
        return error;
    }
}

export async function keycloakUser(dispatch, loginPayload, key) { 
    try {
        const url1="https://smartwcm-gateway-smartwcm-qa1.dark.swcm.link/user/rest/v3/user/userto/applicationid/252/username/"+loginPayload+"/type/username/?swcmTicket=69f2ed48-6850-4e0f-a2e9-90b54c50fd28";
        fetch(url1,{
            headers: {Authorization:  key}
          }).then(res => res.json())
               .then((result )=> {
                         console.log("userTO:", result);
                         if(result && result.userId){
                            isAdmin(dispatch,result,key);
                        }},
                         (error) => {
  
                                    }
                    )
        
    } catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: error });
        return error;
    }
}

async function isAdmin(dispatch, userDetails,key){
    try {
        var response = await customFetch((format(apiUrls.isAdminPath, userDetails.userId)),key);
        userDetails['isAdminUser'] = response;
        dispatch({ type: 'LOGIN_SUCCESS', payload: userDetails });
        localStorage.setItem('currentUser', JSON.stringify(userDetails));
        
        return response;
    } catch (error) {
        return;
    }
}    
export async function isAdminUser(dispatch, userDetails,key) {
        try {
            var response = await customFetch((format(apiUrls.isAdminPath, userDetails.userId)),key);
            userDetails['isAdminUser'] = response;
            dispatch({ type: 'LOGIN_SUCCESS', payload: userDetails });
            localStorage.setItem('currentUser', JSON.stringify(userDetails));
            
            return response;
        } catch (error) {
            return;
        }
    }    

export async function logout(dispatch) {
    dispatch({ type: 'LOGOUT' });
    dispatch({ type: 'REQUEST_TASK_LIST', payload: [] });
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('isAdminUser');
    localStorage.removeItem('keycloakToken');
    return;
}

export async function getTaskList(dispatch, searchData, token) {
   
    let startDate = 0;
    let endDate = 0;
    if (searchData.filterStartDate) {
        startDate = moment(searchData.filterStartDate).format('DD-MM-YYYY');
    }
    if (searchData.filterEndDate) {
        endDate = moment(searchData.filterEndDate).format('DD-MM-YYYY');
    }

    searchData.masterProjectId = searchData.masterProjectId ? searchData.masterProjectId : 0;
    searchData.masterReleaseId = searchData.masterReleaseId ? searchData.masterReleaseId : 0;
    searchData.masterUserId = searchData.masterUserId ? searchData.masterUserId : 0;

    let path = format(apiUrls.tasks, searchData.masterProjectId, searchData.masterReleaseId, searchData.masterUserId, searchData.userStoryId, startDate, endDate);
    
    try {
        let response = await customFetch(path, token);
        dispatch({ type: 'REQUEST_TASK_LIST', payload: response });
        return response;
    } catch (error) {
        return;
    }
}
